import { render, staticRenderFns } from "./binpromotionresume.vue?vue&type=template&id=33aa6ac3&"
import script from "./binpromotionresume.vue?vue&type=script&lang=js&"
export * from "./binpromotionresume.vue?vue&type=script&lang=js&"
import style0 from "./binpromotionresume.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "binpromotionresume.vue"
export default component.exports